<template>
    <button @click="goHome" class="button">
        Home
    </button>
</template>

<script>
export default {
    name: 'HomeButton',
    methods: {
        goHome() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped></style>
