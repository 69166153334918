<template>
    <div class="tool-card bg-white p-6 rounded-lg shadow-md">
        <img :src="`/images/${tool.icon}`" alt="Tool icon" class="w-16 h-16 mb-4 mx-auto">
        <h3 class="text-xl font-semibold mb-2">{{ tool.name }}</h3>
        <p class="text-gray-600">{{ tool.description }}</p>
        <a :href="tool.link" class="button">
            Go
        </a>
    </div>
</template>

<script>
export default {
    name: 'ToolCard',
    props: {
        tool: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
.tool-card {
    max-width: 300px;
    text-align: center;
}
</style>
