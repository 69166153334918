<template>
    <div class="flex flex-col items-center justify-center min-h-screen p-4 sm:p-10 bg-blue-700">
        <div class="flex flex-col sm:flex-row items-center justify-center w-full max-w-5xl">
            <img src="/images/doso.png" alt="DOSO Screenshot"
                class="w-full sm:max-w-1/2 rounded-lg shadow-lg mb-4 sm:mb-0 sm:mr-5">
            <div class="w-full sm:max-w-1/2 text-white text-center sm:text-left">
                <h2 class="text-2xl font-bold mb-2">DOSO</h2>
                <p class="mb-4">The goal of the project is to create a tool capable of quickly recovering all the
                    information linked to a domain name (for example its creation/expiration date, its availability, etc.),
                    then display them in a more readable and faster format.</p>
                <div class="flex justify-center sm:justify-start space-x-2">
                    <a href="http://doso.noa.nyam.dev/" class="button">Visit
                        DOSO</a>
                    <HomeButton />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HomeButton from '@/components/HomeButton.vue';

export default {
    name: 'DosoComponent',
    components: {
        HomeButton
    }
}
</script>

<style scoped>
/* No custom styles needed as Tailwind handles it */
</style>
