<template>
    <div id="app" class="flex flex-col min-h-screen">
        <header class="header w-full bg-neutral-600	 text-white py-4">
            <router-link to="/" class="text-2xl font-bold">Lookatthis 👀</router-link>
        </header>
        <main class="flex-grow">
            <router-view />
        </main>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.header {
    background-color: #e6f2ff;
    padding: 20px 0;
    width: 100%;
    text-align: center;
}

.header .router-link {
    color: white;
    text-decoration: none;
}

.header .router-link:hover {
    text-decoration: underline;
}

.header h1 {
    margin: 0;
    font-size: 2rem;
}
</style>
