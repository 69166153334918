import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from '@/views/HomeComponent.vue';
import DosoComponent from '@/views/DosoComponent.vue';
import CtpComponent from '@/views/CtpComponent.vue';
import WeatherAppComponent from '@/views/WeatherAppComponent.vue';
import CvComponent from '@/views/CvComponent.vue';

const routes = [
    { path: '/', component: HomeComponent },
    { path: '/doso', component: DosoComponent },
    { path: '/ctp', component: CtpComponent },
    { path: '/weatherapp', component: WeatherAppComponent },
    { path: '/cv', component: CvComponent }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
