<template>
    <div class="flex flex-col items-center justify-center min-h-screen p-4 sm:p-10 bg-orange-400">
        <div class="flex flex-col sm:flex-row items-center justify-center w-full max-w-5xl">
            <img src="/images/weatherapp.png" alt="WeatherApp Screenshot"
                class="w-full sm:max-w-1/2 rounded-lg shadow-lg mb-4 sm:mb-0 sm:mr-5">
            <div class="w-full sm:max-w-1/2 text-white text-center sm:text-left">
                <h2 class="text-2xl font-bold mb-2">Java Weather App</h2>
                <p class="mb-4">WeatherApp is a Java-based desktop application that provides real-time weather information.
                </p>
                <div class="flex justify-center sm:justify-start space-x-2">
                    <a href="https://github.com/neo-dl11/JavaWeatherApp" class="button">Visit
                        the GitHub repo</a>
                    <HomeButton />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HomeButton from '@/components/HomeButton.vue';

export default {
    name: 'WeatherAppComponent',
    components: {
        HomeButton
    }
}
</script>

<style scoped>
/* Tailwind handles the styling */
</style>
