<template>
    <div class="home-container flex flex-col items-center justify-center min-h-screen p-10 bg-blue-100">
        <p class="mb-8">My projects :</p>
        <div class="tool-grid flex flex-wrap justify-center gap-6">
            <ToolCard v-for="tool in tools" :key="tool.name" :tool="tool" />
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import ToolCard from '@/components/ToolCard.vue';
import tools from '@/assets/tools.json';

export default {
    name: 'HomeComponent',
    components: {
        ToolCard,
    },
    data() {
        return {
            tools: tools
            // tools: []
        };
    },
    // created() {
    //     axios
    //         .get('/api/tools')
    //         .then((response) => {
    //             this.tools = response.data;
    //         })
    //         .catch((error) => {
    //             console.error('There was an error fetching the tools!', error);
    //         });
    // },
};
</script>

<style scoped>
.tool-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.text-center {
    text-align: center;
}
</style>
